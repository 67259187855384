import Vue from 'vue'

import VueVirtualScroller from 'vue-virtual-scroller'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.prototype.$store = store

Vue.use(VueVirtualScroller)

new Vue({
  store, // 将 Vuex store 对象添加到 Vue 实例的选项中
  render: h => h(App),
  router
}).$mount('#app')
