<template>
  <div class="map-panel">
    <div style="height: 100%">
      <div v-if="detail" id="back-btn" @click="goBack">
        <img src="../static/others/back-img.png" />
      </div>
      <div id="map"></div>
    </div>

    <!-- 显示详情 -->
    <transition name="mapDetailBg">
      <div
        v-if="showDetailModal"
        class="bank-detail-panel"
        @click.stop="showDetailModal = false"
      ></div>
    </transition>
    <transition name="mapDetail">
      <map-detail v-if="showDetailModal" :detailData="detailData" />
    </transition>
  </div>
</template>

<script>
import { myFetch } from "../api/request";
import powerImg from "../static/others/power-bank.png";
import MapDetail from "./MapDetail.vue";

export default {
  name: "Maps",
  components: {
    MapDetail,
  },
  props: {
    detail: {
      type: Boolean,
      default: false,
    },
    storeInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    lat: {
      type: Number,
      default: 40.7485400,
    },
    long: {
      type: Number,
      default: -73.9857600,
    },
  },
  data() {
    return {
      map: null,
      showDetailModal: false,
      detailData: {
        address: "",
        agent: {
          id: 0,
          deviceQuantity: 0,
          onlineQuantity: 0,
          outlineQuantity: 0,
          rentQuantity: 0,
        },
        chargerQuantity: 0,
        code: "",
        creatAt: "",
        freeQuantity: 0,
        id: 0,
        latitude: 0,
        longitude: 0,
        manager: {
          id: 0,
        },
        qrCode: "",
        rate: {
          amount: 0,
          id: 0,
        },
        rentChargerQuantity: 0,
        serviceTime: "",
        status: "",
        store: {
          id: 0,
          rateAmount: 0,
        },
        storeName: "",
      },
    };
  },
  computed: {
    powers() {
      return this.$store.getters.list;
    },
  },
  watch: {
    powers(list) {
      this.setMarker(list);
    },
  },
  methods: {
    // 初始化地图
    initMap() {
      const that = this;

      // 成功获取位置信息的回调函数
      const latitude = this.lat;
      const longitude = this.long;

      const maxLat = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI;
      const myLatLng = new window.google.maps.LatLng(latitude, longitude);
      // 创建新的地图对象，并设置中心点和缩放级别
      const zoom = 15;
      const mapOptions = {
        zoom,
        zoomControl: false, // 缩放
        // minZoom: zoom - 10, // 最小缩放
        center: myLatLng,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        restriction: {
          latLngBounds: {
            north: maxLat,
            south: -maxLat,
            west: -180,
            east: 180,
          },
          strictBounds: true,
        },
        keyboardShortcuts: false,
        fullscreenControl: false, // 全屏展示
        mapTypeControl: false, // 地图类型
        streetViewControl: false, // 街道预览
        clickableIcons: false,
        styles: [
          { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#242f3e" }],
          },
          {
            elementType: "labels.text.fill",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
          },
        ],
      };

      const map = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
      that.map = map;

      // Create a DIV to attach the control UI to the Map.
      const centerControlDiv = document.createElement("div");

      // Create the control. This code calls a function that
      // creates a new instance of a button control.
      if (that.detail) {
        // const backBtn = that.setBackBtn();
        const backBtn = document.getElementById("back-btn");

        if (backBtn) {
          // Append the control to the DIV.
          centerControlDiv.appendChild(backBtn);
        }

        // Add the control to the map at a designated control position
        // by pushing it on the position's array. This code will
        // implicitly add the control to the DOM, through the Map
        // object. You should not attach the control manually.
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(
          centerControlDiv
        );
      }

      // 在地图上添加一个标记
      new google.maps.Marker({
        position: myLatLng,
        map: map,
        title: "My location",
      });

      // console.log("that.powers", that.powers);
      // console.log("that.showDetailModal", that.showDetailModal);
    },
    // initMap() {
    //   const that = this;
    //   // 设置地图的中心点
    //   navigator.geolocation.getCurrentPosition(
    //     function (position) {
    //       // 成功获取位置信息的回调函数
    //       const latitude = position.coords.latitude;
    //       const longitude = position.coords.longitude;
    //       console.log(latitude, longitude);
    //       const maxLat = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI;
    //       const myLatLng = new google.maps.LatLng(latitude, longitude);
    //       // 创建新的地图对象，并设置中心点和缩放级别
    //       const zoom = 18;
    //       const mapOptions = {
    //         zoom,
    //         zoomControl: false, // 缩放
    //         // minZoom: zoom - 10, // 最小缩放
    //         center: myLatLng,
    //         mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    //         restriction: {
    //           latLngBounds: {
    //             north: maxLat,
    //             south: -maxLat,
    //             west: -180,
    //             east: 180,
    //           },
    //           strictBounds: true,
    //         },
    //         keyboardShortcuts: false,
    //         fullscreenControl: false, // 全屏展示
    //         mapTypeControl: false, // 地图类型
    //         streetViewControl: false, // 街道预览
    //         clickableIcons: false,
    //         styles: [
    //           { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    //           {
    //             elementType: "labels.text.stroke",
    //             stylers: [{ color: "#242f3e" }],
    //           },
    //           {
    //             elementType: "labels.text.fill",
    //             stylers: [{ color: "#746855" }],
    //           },
    //           {
    //             featureType: "administrative.locality",
    //             elementType: "labels.text.fill",
    //             stylers: [{ color: "#d59563" }],
    //           },
    //           {
    //             featureType: "poi",
    //             elementType: "labels.text.fill",
    //             stylers: [{ color: "#d59563" }],
    //           },
    //           {
    //             featureType: "poi.park",
    //             elementType: "geometry",
    //             stylers: [{ color: "#263c3f" }],
    //           },
    //           {
    //             featureType: "poi.park",
    //             elementType: "labels.text.fill",
    //             stylers: [{ color: "#6b9a76" }],
    //           },
    //           {
    //             featureType: "road",
    //             elementType: "geometry",
    //             stylers: [{ color: "#38414e" }],
    //           },
    //           {
    //             featureType: "road",
    //             elementType: "geometry.stroke",
    //             stylers: [{ color: "#212a37" }],
    //           },
    //           {
    //             featureType: "road",
    //             elementType: "labels.text.fill",
    //             stylers: [{ color: "#9ca5b3" }],
    //           },
    //           {
    //             featureType: "road.highway",
    //             elementType: "geometry",
    //             stylers: [{ color: "#746855" }],
    //           },
    //           {
    //             featureType: "road.highway",
    //             elementType: "geometry.stroke",
    //             stylers: [{ color: "#1f2835" }],
    //           },
    //           {
    //             featureType: "road.highway",
    //             elementType: "labels.text.fill",
    //             stylers: [{ color: "#f3d19c" }],
    //           },
    //           {
    //             featureType: "transit",
    //             elementType: "geometry",
    //             stylers: [{ color: "#2f3948" }],
    //           },
    //           {
    //             featureType: "transit.station",
    //             elementType: "labels.text.fill",
    //             stylers: [{ color: "#d59563" }],
    //           },
    //           {
    //             featureType: "water",
    //             elementType: "geometry",
    //             stylers: [{ color: "#17263c" }],
    //           },
    //           {
    //             featureType: "water",
    //             elementType: "labels.text.fill",
    //             stylers: [{ color: "#515c6d" }],
    //           },
    //           {
    //             featureType: "water",
    //             elementType: "labels.text.stroke",
    //             stylers: [{ color: "#17263c" }],
    //           },
    //         ],
    //       };

    //       const map = new google.maps.Map(
    //         document.getElementById("map"),
    //         mapOptions
    //       );
    //       that.map = map;

    //       // Create a DIV to attach the control UI to the Map.
    //       const centerControlDiv = document.createElement("div");

    //       // Create the control. This code calls a function that
    //       // creates a new instance of a button control.
    //       if (that.detail) {
    //         // const backBtn = that.setBackBtn();
    //         const backBtn = document.getElementById("back-btn");

    //         if (backBtn) {
    //           // Append the control to the DIV.
    //           centerControlDiv.appendChild(backBtn);
    //         }

    //         // Add the control to the map at a designated control position
    //         // by pushing it on the position's array. This code will
    //         // implicitly add the control to the DOM, through the Map
    //         // object. You should not attach the control manually.
    //         map.controls[google.maps.ControlPosition.TOP_LEFT].push(
    //           centerControlDiv
    //         );
    //       }

    //       // 在地图上添加一个标记
    //       new google.maps.Marker({
    //         position: myLatLng,
    //         map: map,
    //         title: "My location",
    //       });

    //       // console.log("that.powers", that.powers);
    //       // console.log("that.showDetailModal", that.showDetailModal);
    //     },
    //     function (error) {
    //       // 获取位置信息失败的回调函数
    //       // 处理错误
    //       console.error(error);
    //       // alert("Get current position fail. Error message:" + error.message);
    //     }
    //   );
    // },
    setMarker(list) {
      const that = this;
      const markers = []; // 存储创建的标记
      const listeners = []; // 存储事件监听器

      const icon = {
        url: powerImg,
      };
      list.forEach((item) => {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(item.latitude, item.longitude),
          map: null, // 初始时不将标记添加到地图
          icon,
          data: item,
        });

        markers.push(marker);

        const listener = google.maps.event.addListener(
          marker,
          "click",
          async (e) => {
            // 处理标记点击事件的逻辑
            console.log(marker.data);
            // 获取详情
            that.showDetailModal = true;
            that.detailData = marker.data;
            // 调取接口后获取更加详细内容
            const detailInfo = await myFetch({
              url: "chargerBox/get",
              method: "POST",
              data: { id: marker.data.id },
            });

            if (detailInfo.code === 200) {
              that.detailData = detailInfo.data;
            }
          },
          false
        );

        listeners.push(listener);
      });

      // 一次性将所有标记添加到地图
      markers.forEach((marker) => {
        marker.setMap(that.map);
      });

      // 在适当的时机移除事件监听器
      that.$on("hook:beforeDestroy", () => {
        markers.forEach((marker) => {
          marker.setMap(null);
        });

        listeners.forEach((listener) => {
          google.maps.event.removeListener(listener);
        });
      });
    },
    goBack() {
      this.$router.back();
    },
    setCenter(lat, lng) {
      const center = new google.maps.LatLng(lat, lng);
      this.map.setCenter(center);
    },
  },
  created() {
    this.$store.dispatch("fetchList");
  },
  mounted() {
    const that = this;
    document?.addEventListener("initMapReady", function () {
      console.log("init");
      that.initMap();
    });
    document?.addEventListener("UniAppJSBridgeReady", function () {
      // that.createCenterControl();
      that.initMap();
    });
    that.initMap();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map-panel {
  height: 100%;
}
#map {
  width: inherit;
  height: inherit;
}

#back-btn {
  position: absolute;
  left: 8.41vw;
  top: 6.31vw;
  width: 8.88vw;
  height: 8.88vw;
  background: rgb(36, 197, 81);
  box-shadow: 0px 0px 8.65px rgb(36, 197, 81);
  border-radius: 8.88vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
#back-btn img {
  width: 4.67vw;
  height: 4.67vw;
}

.bank-detail-panel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.mapDetailBg-enter-active {
  animation: mapDetailBg 0.3s;
}

.mapDetailBg-leave-active {
  animation: mapDetailBg 0.3s reverse;
}
@keyframes mapDetailBg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
