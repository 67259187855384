<template>
  <div class="lify-list" :class="className">
    <!-- icon -->
    <div class="lify-info">
      <slot name="icon"></slot>
      <div class="title" :class="titleClassName">{{ title }}</div>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "LifyList",
  props: {
    title: String,
    className: String,
    titleClassName: String,
  },
};
</script>

<style scoped>
.lify-list {
  margin: 3.5vw 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lify-info {
  display: flex;
  align-items: center;
}

.lify-list .title {
  height: 5.84vw;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 4.21vw;
  font-weight: 300;
  line-height: 5.84vw;
}
</style>
