<template>
  <div>
    <div class="tabbar-panel">
      <div
        class="tabbar-item"
        v-for="(item, index) in list"
        :key="index"
        @click="handleClick(index)"
        :id="'tabbar-item-' + item.name"
      >
        <div :class="item.name === 'scan' ? 'scan' : ''">
          <img :src="activeIndex === index ? item.img_a : item.img" />
        </div>
      </div>
    </div>
    <div class="tabbar-holder"></div>
  </div>
</template>

<script>
export default {
  name: "TabBar",
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      list: [
        {
          name: "home",
          // img: require("../static/tabbar/home.png"),
          img: require("../static/tabbar/home.png"),
          img_a: require("../static/tabbar/home-a.png"),
          url: "",
        },
        {
          name: "station",
          img: require("../static/tabbar/station.png"),
          img_a: require("../static/tabbar/station-a.png"),
          url: "",
        },
        {
          name: "scan",
          img: require("../static/tabbar/camera.png"),
          img_a: require("../static/tabbar/camera.png"),
        },
        {
          name: "activity",
          img: require("../static/tabbar/activity.png"),
          img_a: require("../static/tabbar/activity-a.png"),
          url: "",
        },
        {
          name: "my",
          img: require("../static/tabbar/my.png"),
          img_a: require("../static/tabbar/my-a.png"),
          url: "",
        },
      ],
    };
  },
  methods: {
    handleClick(index) {
      const that = this;
      if (index === 2) {
        // 扫码
        const btn = document.getElementById("tabbar-item-scan");

        btn?.addEventListener("click", function () {
          uni.navigateTo({
            url: "/pages/Scan/Scan",
          });
          that.$emit("close");
        });
      } else {
        this.$emit("changeIndex", index);
      }
    },
  },
};
</script>

<style scoped>
.tabbar-panel {
  position: fixed;
  bottom: calc(7.01vw + env(safe-area-inset-bottom));
  width: 89.72vw;
  height: 15.65vw;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(17, 19, 16);
  box-shadow: 0vw 0.93vw 4.67vw rgba(0, 0, 0, 0.05);
  border-radius: 23.36vw;
  display: flex;
  z-index: 1000;
}

.tabbar-holder {
  height: 15.65vw;
  width: 100vw;
  padding-bottom: calc(7.01vw + env(safe-area-inset-bottom));
}

.tabbar-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabbar-item .scan {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.81vw;
  height: 9.81vw;
  background: rgb(36, 197, 81);
  box-shadow: 0.7vw 0.7vw 11.68vw rgba(255, 255, 255, 0.25);
  border-radius: 50%;
}

.tabbar-item .scan::after {
  position: absolute;
  left: -0.235vw;
  top: -0.235.vw;
  width: 10.28vw;
  height: 10.28vw;
  border-radius: 50%;
  display: block;
  content: "";
  background: rgba(36, 197, 81, 0.1);
  box-shadow: 0vw 0vw 2.02vw rgb(36, 197, 81);
}

.tabbar-item img {
  width: 4.67vw;
  height: 4.67vw;
}
</style>
