<template>
  <div class="my-panel">
    <bg-option></bg-option>
    <div class="tp-panel">
      <div class="tp-content">
        <div class="tp-avatar-panel">
          <!-- <img class="avatar" src="../assets/logo.png" /> -->
          <img class="avatar" :src="user.avatar" />
          <div class="camera-panel" @click="showAvatarControl = true">
            <img class="camera" src="../static/others/camera.png" />
          </div>
        </div>
        <div class="tp-nickname">
          {{ $store.state.lang === "zh" ? "您好" : "Hello" }},
          {{
            user.nickname ||
            `Lify+ ${$store.state.lang === "zh" ? "用户" : "User"}`
          }}!
        </div>
      </div>
    </div>

    <!-- settings -->
    <div class="setting-panel">
      <div id="my-account">
        <lify-list
          :title="$store.state.lang === 'zh' ? '个人信息' : 'Account Settings'"
        >
          <template v-slot:icon>
            <img class="icon" src="../static/settings/settings.png" />
          </template>
          <template v-slot:content>
            <div class="content right"></div>
          </template>
        </lify-list>
      </div>
      <!-- <lify-list id="my-notifications" title="通知">
        <template v-slot:icon>
          <img class="icon" src="../static/settings/notify.png" />
        </template>
        <template v-slot:content>
          <div class="content right"></div>
        </template>
      </lify-list> -->
      <lify-list
        id="my-invitation"
        :title="$store.state.lang === 'zh' ? '邀请码' : 'Invitations'"
      >
        <template v-slot:icon>
          <img class="icon" src="../static/settings/invitaions.png" />
        </template>
        <template v-slot:content>
          <div class="content right"></div>
        </template>
      </lify-list>
      <lify-list
        id="my-language"
        :title="$store.state.lang === 'zh' ? '选择语言' : 'Select Language'"
      >
        <template v-slot:icon>
          <img class="icon" src="../static/settings/language.png" />
        </template>
        <template v-slot:content>
          <div class="content" @click="showLangControl = true">
            {{ $store.state.lang === "zh" ? "简体中文, 中国" : "English, US" }}
          </div>
        </template>
      </lify-list>
      <div class="divider-panel">
        <lify-divider center></lify-divider>
      </div>

      <div class="title">
        {{ $store.state.lang === "zh" ? "支持" : "Support" }}
      </div>
      <lify-list
        id="my-about"
        :title="$store.state.lang === 'zh' ? '关于我们' : 'About'"
      >
        <template v-slot:icon>
          <img class="icon" src="../static/settings/about.png" />
        </template>
        <template v-slot:content>
          <div class="content right"></div>
        </template>
      </lify-list>
      <lify-list
        id="my-support"
        :title="$store.state.lang === 'zh' ? '客服中心' : 'Customer Support'"
      >
        <template v-slot:icon>
          <img class="icon" src="../static/settings/customer.png" />
        </template>
        <template v-slot:content>
          <div class="content right"></div>
        </template>
      </lify-list>
      <lify-list
        id="my-guide"
        :title="$store.state.lang === 'zh' ? '使用指南' : 'User guide'"
      >
        <template v-slot:icon>
          <img class="icon" src="../static/settings/guide.png" />
        </template>
        <template v-slot:content>
          <div class="content right"></div>
        </template>
      </lify-list>
      <lify-list
        id="my-privacy"
        :title="$store.state.lang === 'zh' ? '隐私政策' : 'Privacy Policy'"
      >
        <template v-slot:icon>
          <img class="icon" src="../static/settings/policy.png" />
        </template>
        <template v-slot:content>
          <div class="content right"></div>
        </template>
      </lify-list>
      <lify-list
        id="my-agreement"
        :title="$store.state.lang === 'zh' ? '用户协议' : 'User Agreement'"
      >
        <template v-slot:icon>
          <img class="icon" src="../static/settings/policy.png" />
        </template>
        <template v-slot:content>
          <div class="content right"></div>
        </template>
      </lify-list>
    </div>

    <!-- 上传图片 -->
    <transition name="avatarPanel">
      <avatar-upload
        v-if="showAvatarControl"
        @close="showAvatarControl = false"
      />
    </transition>
    <transition name="avatarBgPanel">
      <div
        v-if="showAvatarControl"
        class="bank-detail-panel"
        @click="showAvatarControl = false"
      ></div>
    </transition>

    <!-- 修改语言 -->
    <transition name="langPanel">
      <lang-change v-if="showLangControl" @close="selectLang" />
    </transition>
    <transition name="langBgPanel">
      <div
        v-if="showLangControl"
        class="bank-detail-panel"
        @click="showLangControl = false"
      ></div>
    </transition>
  </div>
</template>

<script>
import AvatarUpload from "../components/AvatarUpload.vue";
import BgOption from "../components/BgCommon/BgOption.vue";
import LangChange from "../components/LangChange.vue";
import LifyDivider from "../components/Lify/LifyDivider.vue";
import LifyList from "../components/Lify/LifyList.vue";
export default {
  components: { BgOption, LifyList, LifyDivider, AvatarUpload, LangChange },
  name: "My",
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      showAvatarControl: false,
      showLangControl: false,
    };
  },
  mounted() {
    const btnMyAccount = document.getElementById("my-account");
    btnMyAccount?.addEventListener("click", function () {
      console.log(123123);
      uni.navigateTo({
        url: "/pages/UserInfo/AccountSetting",
      });
    });

    const btnMyNotifications = document.getElementById("my-notifications");
    btnMyNotifications?.addEventListener("click", function () {
      uni.navigateTo({
        url: "/pages/UserInfo/Notifications",
      });
    });

    const btnMyInvitation = document.getElementById("my-invitation");
    btnMyInvitation?.addEventListener("click", function () {
      uni.navigateTo({
        url: "/pages/UserInfo/Invitations",
      });
    });

    const btnMyAbout = document.getElementById("my-about");
    btnMyAbout?.addEventListener("click", function () {
      uni.navigateTo({
        url: "/pages/UserInfo/AboutLify",
      });
    });

    const btnMySupport = document.getElementById("my-support");
    btnMySupport?.addEventListener("click", function () {
      uni.navigateTo({
        url: "/pages/UserInfo/Support",
      });
    });

    const btnMyGuide = document.getElementById("my-guide");
    btnMyGuide?.addEventListener("click", function () {
      uni.navigateTo({
        url: "/pages/UserGuide/UserGuide",
      });
    });

    const btnMyPrivacy = document.getElementById("my-privacy");
    btnMyPrivacy?.addEventListener("click", function () {
      uni.navigateTo({
        url: "/pages/UserInfo/PrivacyPolicy",
      });
    });

    const btnMyAgreement = document.getElementById("my-agreement");
    btnMyAgreement?.addEventListener("click", function () {
      uni.navigateTo({
        url: "/pages/UserInfo/UserAgreement",
      });
    });
  },
  methods: {
    selectLang(lang) {
      this.showLangControl = false;
      // console.log(lang);
      this.$store.commit("updateLang", lang);
      //发送跨域信息
      window.parent.postMessage(lang, "*");
      uni.postMessage({
        data: { lang },
      });
      uni.redirectTo({
        url: "/pages/index/index",
      });
    },
  },
};
</script>

<style scoped>
.title {
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 5.61vw;
  font-weight: 600;
  line-height: 7.71vw;
}
.setting-panel {
  position: relative;
  margin: 7.94vw;
  z-index: 1;
}
.tp-panel {
  position: relative;
  width: 100vw;
  height: 44.39vw;
  background: rgba(242, 242, 242, 0.1);
  backdrop-filter: blur(4.67vw);
  border-radius: 0 0 3.04vw 3.04vw;
}

.tp-content {
  position: absolute;
  left: 50%;
  top: 8.64vw;
  transform: translateX(-50%);
}

.tp-avatar-panel {
  position: relative;
  width: 20.09vw;
  height: 20.09vw;
  margin: auto;
  border-radius: 20.09vw;
  background-color: #fff;
}

.tp-avatar-panel .avatar {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16.072vw;
  height: 16.072vw;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.camera-panel {
  position: absolute;
  right: 1vw;
  bottom: 0;
  width: 5.61vw;
  height: 5.61vw;
  border-radius: 5.61vw;
  background: rgb(36, 197, 81);
  box-shadow: 0vw 0vw 2.02vw rgb(36, 197, 81);
  display: flex;
  align-items: center;
  justify-content: center;
}
.tp-avatar-panel .camera {
  width: 2.98vw;
  height: 2.98vw;
}
.tp-nickname {
  margin-top: 2.05vw;
  height: 7.94vw;
  color: rgb(255, 253, 247);
  font-family: Nunito;
  font-size: 5.4vw;
  font-weight: 500;
  line-height: 7.94vw;
}
.content {
  height: 5.14vw;
  opacity: 0.5;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 3.74vw;
  font-weight: 300;
  line-height: 5.14vw;
}
.icon {
  width: 4.67vw;
  height: 4.67vw;
  margin-right: 2.1vw;
}

.right {
  opacity: 0.5;
  display: flex;
  align-items: center;
}
.right::after {
  margin-left: 2.57vw;
  content: "";
  display: block;
  background-image: url(../static/others/right-arrow-gray.png);
  width: 3.03vw;
  height: 2.96vw;
  background-size: 100%;
}

.divider-panel {
  margin: 5.84vw 0;
}

.avatarBgPanel-enter-active {
  animation: avatarBgPanel 0.3s;
}

.avatarBgPanel-leave-active {
  animation: avatarBgPanel 0.3s reverse;
}
@keyframes avatarBgPanel {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.langBgPanel-enter-active {
  animation: langBgPanel 0.3s;
}

.langBgPanel-leave-active {
  animation: langBgPanel 0.3s reverse;
}
@keyframes langBgPanel {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.avatarPanel-enter-active {
  animation: slider 0.3s;
}
.avatarPanel-leave-active {
  animation: slider 0.3s reverse;
}
@keyframes slider {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.bank-detail-panel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1001;
}
</style>
