<template>
  <div class="home-panel">
    <bg-option></bg-option>
    <!-- tips -->
    <tips></tips>
    <!-- <div class="title">Around You</div> -->
    <div class="title">
      {{ $store.state.lang === "zh" ? "当前定位" : "Around You" }}
    </div>
    <div class="map-home" @click="navToDetail">
      <maps :lat="lat" :long="long" />
    </div>
    <!-- <div class="title">Closest to you</div> -->
    <div class="title">
      {{ $store.state.lang === "zh" ? "附近门店" : "Closest to you" }}
    </div>
    <list :list="closestList"></list>
  </div>
</template>

<script>
import { myFetch } from "../api/request";
import BgOption from "../components/BgCommon/BgOption.vue";
import List from "../components/List.vue";
import Maps from "../components/Maps.vue";
import Tips from "../components/Tips.vue";
export default {
  name: "Home",
  props: {
    lat: {
      type: Number,
      default: 40.74854,
    },
    long: {
      type: Number,
      default: -73.98576,
    },
  },
  components: { Tips, BgOption, Maps, List },
  data() {
    return {
      closestList: [],
    };
  },
  methods: {
    navToDetail() {
      this.$router.push("/detail");
    },
    async getClosePank() {
      const that = this;
      // 处理位置信息
      const response = await myFetch({
        url: "chargerBox/findByLonAndLat",
        method: "POST",
        data: {
          longitude: this.long,
          latitude: this.lat,
        },
      });
      if (response.code === 200) {
        that.closestList = response.data.slice(0, 5);
      }
    },
    // getClosePank() {
    //   const that = this;
    //   navigator.geolocation.getCurrentPosition(
    //     async function (position) {
    //       // 成功获取位置信息的回调函数
    //       const latitude = position.coords.latitude;
    //       const longitude = position.coords.longitude;

    //       // 处理位置信息
    //       const response = await myFetch({
    //         url: "chargerBox/findByLonAndLat",
    //         method: "POST",
    //         data: {
    //           longitude,
    //           latitude,
    //         },
    //       });
    //       if (response.code === 200) {
    //         that.closestList = response.data.slice(0, 5);
    //       }
    //     },
    //     function (error) {
    //       // 获取位置信息失败的回调函数
    //       // 处理错误
    //       console.error(error);
    //       // alert("Get current position fail. Error message:" + error.message);
    //     }
    //   );
    // },
  },
  created() {
    // 获取附近充电桩
    this.getClosePank();
  },
};
</script>

<style scoped>
.home-panel {
  padding-top: 12.41vw;
  margin: 0 5.61vw;
}
.title {
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 5.61vw;
  font-weight: 600;
  line-height: 7.71vw;
  padding: 5.84vw 0 2.57vw;
}

.map-home {
  height: 91.59vw;
  width: 88.79vw;
  /* btn-shadow */
  box-shadow: 0.93vw 0.93vw 9.35vw rgba(255, 255, 255, 0.1);
  border-radius: 2.34vw;
}
</style>
