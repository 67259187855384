<template>
  <div class="tips-panel">
    <div class="tips-text">
      <!-- <div class="title">Two hours of usage for free!</div> -->
      <div class="title">
        {{
          $store.state.lang === "zh" ? "欢迎使用LIFY+。" : "Welcome to LIFY+."
        }}
      </div>
      <div class="desc">
        {{
          $store.state.lang === "zh"
            ? "请扫LIFY+充电站的二维码租借充电宝。"
            : "Please scan the QR code of the LIFY+ charging station to rent a power bank."
        }}
        <!-- Scan the QR Code in the store to get 2 hours of free charging -->
      </div>
      <!-- <div id="scan-coupon" class="btn" @click="navScan">Scan Coupon</div> -->
    </div>
    <div class="tips-img">
      <img src="../static/people.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Tips",
  methods: {
    navScan() {
      const btn = document.getElementById("scan-coupon");
      console.log(btn);
      btn?.addEventListener("click", function () {
        console.log(uni);
        uni.navigateTo({
          url: "/pages/Scan/Scan",
        });
      });
    },
  },
};
</script>

<style scoped>
.tips-panel {
  position: relative;
  width: 88.79vw;
  height: 32.94vw;
  box-sizing: border-box;
  /* FFFDF7 */
  border: 0.23vw solid rgb(255, 253, 247, 0.2);
  /* card-shadow */
  box-shadow: 0vw 0.93vw 4.67vw rgba(0, 0, 0, 0.05);
  border-radius: 2.34vw;
}
.tips-text {
  padding: 6.54vw 6.54vw 6.54vw 4.44vw;
}
.title {
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 4.21vw;
  font-weight: 500;
  line-height: 6.31vw;
  letter-spacing: 0%;
  margin: 0.23vw 0;
}
.desc {
  width: 53.74vw;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 3.27vw;
  font-weight: 300;
  line-height: 4.44vw;
  margin: 0.23vw 0;
}
.btn {
  color: rgb(36, 197, 81);
  font-family: Nunito;
  font-size: 3.27vw;
  font-weight: 600;
  line-height: 4.44vw;
  margin-right: 0.47vw;
}
.tips-img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30.37vw;
  height: 32.94vw;
}

.tips-img img {
  width: 30.37vw;
  height: 32.94vw;
}
</style>
