<template>
  <div class="avatar-panel">
    <!-- <div class="list" @click="$emit('click', 'change')">
      Change Profile Picture
    </div> -->
    <div class="list">
      <label for="avatar-upload">
        {{
          $store.state.lang === "zh"
            ? "更改个人资料图片"
            : "Change Profile Picture"
        }}
      </label>
      <input
        id="avatar-upload"
        type="file"
        hidden
        accept="image/*"
        @change="handleFileSelect"
      />
    </div>
    <lify-divider center></lify-divider>
    <div class="list" @click="$emit('click', 'del')">
      {{
        $store.state.lang === "zh"
          ? "删除个人资料图片"
          : "Delete Profile Picture"
      }}
    </div>
  </div>
</template>

<script>
import { myFetch } from "../api/request";
import LifyDivider from "./Lify/LifyDivider.vue";
export default {
  components: { LifyDivider },
  name: "AvatarUpload",
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {};
  },
  methods: {
    async handleFileSelect(event) {
      const that = this;
      const file = event.target.files[0];
      if (!file || !file.type.match(/^image\//)) {
        return;
      }
      const formData = new FormData();
      formData.append("part", file);
      console.log(formData);
      fetch("/api/attachment/upload", {
        method: "POST",
        body: formData,
      })
        .then(async (response) => {
          const res = await response.json();
          console.log(res);
          if (res.code === 200) {
            // 更新用户信息
            const userData = {
              avatar: "https://www.iroigroup.com/attachment/" + res.data || "",
              // nickname: that.user?.nickname || "",
              // birthday: that.user?.birthday || "",
              // sex: that.user?.sex,
            };
            const userRes = await myFetch({
              url: "user/update",
              method: "POST",
              data: { user: userData },
            });
            if (userRes.code === 200) {
              that.$store.dispatch("fetchUserInfo");
            }
          }
        })
        .catch((error) => {
          console.error("上传失败", error);
        })
        .finally(() => {
          that.$emit("close");
        });
    },
  },
};
</script>

<style scoped>
.avatar-panel {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgb(17, 19, 16);
  border-radius: 4.67vw 4.67vw 0 0;
  z-index: 1002;
  color: #fff;
  padding: 9.35vw 12.15vw;
  box-sizing: border-box;
}

.list {
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 4.21vw;
  font-weight: 300;
  margin: 3.5vw 0;
}
.list:last-child {
  color: rgb(222, 89, 97);
}
</style>
