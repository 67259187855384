<template>
  <div
    class="lify-divider"
    :class="[center ? 'center' : '', colorType === 'green' ? 'green' : '']"
    :style="{ width: width }"
  ></div>
</template>

<script>
export default {
  name: "LifyDivider",
  props: {
    className: String,
    center: Boolean,
    colorType: {
      type: String,
      default: "white", // white or green
    },
    width: {
      type: String,
      default: "75.7vw",
    },
  },
};
</script>

<style scoped>
.lify-divider {
  height: 0;
  opacity: 0.2;
  /* main colors/FFFFFF */
  border: 0.23vw solid rgb(255, 255, 255);
}
.lify-divider.green {
  border: 0.23vw solid rgb(36, 197, 81);
  opacity: 1;
}
.center {
  margin: 0 auto;
}
</style>
