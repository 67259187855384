// 导入模块
import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index';
import Detail from '../views/Detail'

// 注册路由组件
Vue.use(VueRouter);

//编写路由规则
const routes = [
  {
    path: "/",
    name: 'Index',
    component: Index
  },
  {
    path: "/detail",
    name: "Detail",
    component: Detail
  }
]

//创建路由
const router = new VueRouter({
  mode: 'history',
  routes
})
//导出
export default router;