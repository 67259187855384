import store from "../store"

export function myFetch(options) {
  return new Promise((resolve, reject) => {
    fetch("/api/" + options.url, {
      method: options.method || 'GET',
      headers: {
        'Access-Token': store.getters.token,
        'Content-Type': "application/json",
      },
      body: JSON.stringify(options.data)
    }).then(function (response) {
      resolve(response.json())
      // 处理 HTTP 响应
    }, function (error) {
      // 处理网络错误
      reject(error)
    })
  })
}