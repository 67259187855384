<template>
  <div class="map-detail-panel">
    <img class="map-img" :src="detailData.logo" />
    <div class="info-panel">
      <div class="info-detail">
        <div class="title-icon">
          <img src="../static/others/location-w.png" />
          <span>
            {{ $store.state.lang === "zh" ? "地址" : "Location" }}
          </span>
        </div>
        <div class="title-content">
          {{ detailData.name || detailData.address }}
        </div>
        <div class="title-address">{{ detailData.address }}</div>
        <div class="divider-panel">
          <lify-divider colorType="green" center></lify-divider>
        </div>

        <div v-if="detailData.serviceTime" class="bank-info">
          <img src="../static/others/time-w.png" />
          <span>{{ detailData.serviceTime }}</span>
        </div>
        <div class="charger-info-detail">
          <div class="detail-item">
            -$2 {{ $store.state.lang === "zh" ? "每小时" : "an hour" }}
          </div>
          <div class="detail-item">
            -$10 {{ $store.state.lang === "zh" ? "每天" : "a day" }}
          </div>
          <div class="detail-item">
            -
            {{
              $store.state.lang === "zh"
                ? "48 小时不还 收取押金"
                : "Deposit not returned within 48 hours will be charged"
            }}
          </div>

          <div class="charger-pic">
            <img src="../static/others/powerbank.png" />
          </div>
        </div>
        <!-- <div class="bank-info">
          <img src="../static/others/power-w.png" />
          <span>{{ detailData.freeQuantity }} Devices Available</span>
        </div>
        <div v-if="detailData.distance" class="bank-info">
          <img src="../static/others/walk-w.png" />
          <span>{{ detailData.distance }}m away from your location</span>
        </div> -->
        <div class="bank-info">
          <div class="can-rent info-item">
            <img src="../static/others/power-w.png" />
            <span>
              {{ detailData.freeQuantity }}
              {{
                $store.state.lang === "zh"
                  ? "台可用的设备"
                  : "Devices Available"
              }}
            </span>
          </div>
          <div class="can-return info-item">
            <img src="../static/others/star-w.png" />
            <span>
              {{ detailData.rentChargerQuantity }}
              {{
                $store.state.lang === "zh" ? "可归还插槽" : "Returnable slot"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="btn-panel">
        <lify-button
          :text="$store.state.lang === 'zh' ? '导航' : 'Navigate'"
          @click="navToDetail"
        ></lify-button>
        <lify-button
          :reverse="true"
          :needIcon="false"
          :text="$store.state.lang === 'zh' ? '扫码租赁' : 'Scan'"
          @click="scan"
        ></lify-button>
      </div>
    </div>
  </div>
</template>

<script>
import LifyButton from "./Lify/LifyButton.vue";
import LifyDivider from "./Lify/LifyDivider.vue";
export default {
  components: { LifyDivider, LifyButton },
  name: "MapDetail",
  props: {
    detailData: {
      type: Object,
      default: function () {
        return {
          address: "",
          agent: {
            id: 0,
            deviceQuantity: 0,
            onlineQuantity: 0,
            outlineQuantity: 0,
            rentQuantity: 0,
          },
          logo: "",
          chargerQuantity: 0,
          code: "",
          creatAt: "",
          freeQuantity: 0,
          id: 0,
          latitude: 0,
          longitude: 0,
          manager: {
            id: 0,
          },
          qrCode: "",
          rate: {
            amount: 0,
            id: 0,
          },
          rentChargerQuantity: 0,
          serviceTime: "",
          status: "",
          store: {
            id: 0,
            rateAmount: 0,
          },
          storeName: "",
        };
      },
    },
  },
  methods: {
    navToDetail() {
      // console.log(this.detailData);
      // console.log(
      //   "url",
      //   `/pages/Navigate/Navigate?name=${this.detailData.address}&latitude=${this.detailData.latitude}&longitude=${this.detailData.longitude}`
      // );
      uni.navigateTo({
        url: `/pages/Navigate/Navigate?name=${this.detailData.address}&latitude=${this.detailData.latitude}&longitude=${this.detailData.longitude}`,
      });
    },
    scan() {
      uni.navigateTo({
        url: "/pages/Scan/Scan",
      });
    },
  },
};
</script>

<style scoped>
.mapDetail-enter-active {
  animation: showArea 0.3s;
}

.mapDetail-leave-active {
  animation: showArea 0.3s reverse;
}

@keyframes showArea {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.map-detail-panel {
  /* Rectangle 22 */
  position: absolute;
  width: 100vw;
  /* height: 93.51vw; */
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(28, 36, 31);
  border-radius: 4.67vw 4.67vw 0 0;
  z-index: 1002;
}

.map-img {
  position: absolute;
  left: 50%;
  top: -14.95vw;
  transform: translate(-50%, -50%);
  width: 83.18vw;
  height: 55.37vw;
  border-radius: 4.67vw;
  /* background-color: rgb(36, 197, 81); */
  object-fit: cover;
}

.map-img[src=""],
.map-img:not([src]) {
  visibility: hidden;
}

.info-panel {
  margin: 22.2vw 8.18vw 10.98vw;
}

.info-detail {
  margin: 0 5.14vw 5.84vw;
}

.title-icon {
  display: flex;
  align-items: center;
}

.title-icon span {
  opacity: 0.4;
  color: rgb(255, 253, 247);
  font-family: Nunito;
  font-size: 2.34vw;
  font-weight: 400;
  line-height: 3.27vw;
  margin-left: 1.17vw;
}

.title-content {
  margin-top: 1.87vw;
  color: rgb(255, 253, 247);
  font-family: Nunito;
  font-size: 3.74vw;
  font-weight: 500;
  line-height: 5.61vw;
  height: 5.61vw;
  overflow: hidden;
}

.title-address {
  color: rgb(255, 253, 247);
  margin: 0.93vw 0;
  font-size: 2.8vw;
}

.title-serviceTime {
  color: green;
}

.divider-panel {
  margin: 4.67vw 0;
}

.charger-info-detail {
  position: relative;
  opacity: 0.6;
  color: rgb(255, 253, 247);
  font-family: Nunito;
  font-size: 2.34vw;
  font-weight: 400;
  line-height: 3.27vw;
  margin-bottom: 5.17vw;
}

.charger-pic {
  position: absolute;
  right: -10vw;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 14vw;
  height: 14vw;
}

.charger-pic img {
  width: 100%;
  height: 100%;
  border-radius: 10%;
}

.detail-item {
  margin-bottom: 1.4vw;
}

.bank-info,
.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 2.1vw;
}

.can-rent,
.can-return {
  color: rgb(255, 253, 247);
  margin-right: 3.333vw;
}

.bank-info span {
  margin-left: 2.34vw;
  height: 4.91vw;
  color: rgb(255, 253, 247);
  font-family: Mukta;
  font-size: 3.27vw;
  font-weight: 400;
  line-height: 4.91vw;
}

.bank-info:last-child {
  margin-bottom: 4.67vw;
}

.title-icon img {
  width: 3.27vw;
  height: 3.27vw;
}

.btn-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-panel button {
  flex-basis: 48%;
}
</style>
