<template>
  <div class="lang-change">
    <!-- <div class="list" @click="$emit('click', 'change')">
      Change Profile Picture
    </div> -->
    <div class="list" @click="$emit('close', 'en')">
      <label for="lang-change">
        English, US
      </label>
    </div>
    <lify-divider center></lify-divider>
    <div class="list" @click="$emit('close', 'zh')">
      <label for="lang-change">
        简体中文, 中国
      </label>
    </div>
  </div>
</template>

<script>
import LifyDivider from "./Lify/LifyDivider.vue";
export default {
  components: { LifyDivider },
  name: "LangChange",
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.lang-change {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgb(17, 19, 16);
  border-radius: 4.67vw 4.67vw 0 0;
  z-index: 1002;
  color: #fff;
  padding: 9.35vw 12.15vw;
  box-sizing: border-box;
}

.list {
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 4.21vw;
  font-weight: 300;
  margin: 3.5vw 0;
}
</style>
