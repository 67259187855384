<template>
  <div class="bg-panel">
    <!-- <div class="bg-black"> </div>
    <div class="circle-1"></div>
    <div class="circle-2"></div> -->
    <img class="bg-dark" src="../../static/Bg/bg-dark3-s.png" />
  </div>
</template>

<script>
export default {
  name: "BgOption",
};
</script>

<style>
.bg-dark {
  position: absolute;
  left: -120vw;
  top: -100vw;
  width: 350vw;
  height: 461.15vw;
}
.bg-panel {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
