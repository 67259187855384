<template>
  <div class="card-panel" :style="{ width: width }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "LifyCard",
  props: {
    width: {
      type: String,
      default: "83.18vw",
    },
  },
};
</script>

<style scoped>
.card-panel {
  background: rgba(242, 242, 242, 0.15);
  backdrop-filter: blur(4.67vw);
  border-radius: 3.04vw;
  padding: 6.78vw 4.67vw;
  margin-bottom: 4.67vw;
  box-sizing: border-box;
}
</style>
