<template>
  <div class="slider-panel">
    <div class="slider-content">
      <div class="user-info-panel">
        <div class="user-avatar" @click="navAccount">
          <img :src="user.avatar" />
        </div>
        <div class="user-info">
          <div class="user-name">{{ user.nickname }}</div>
          <!-- <div class="user-desc">View Profile</div> -->
        </div>
      </div>
      <div class="list-panel">
        <div
          class="list-item"
          v-for="item in list"
          :key="item.name"
          @click="navInfo(item)"
        >
          <div class="icon">
            <img :src="item.img" />
          </div>
          <div>{{ item.name }}</div>
        </div>
      </div>
      
      <!-- 退出登录 -->
      <template v-if="$store.state.iframe === 'h5'">
        <div v-if="token" class="footer-panel">
          <lify-divider width="57.01vw"></lify-divider>
          <div class="list-panel" style="margin-top: 7.48vw">
            <div class="list-item" @click="logout">
              <div class="icon">
                <img :src="logoutBtn.img" />
              </div>
              <div>{{ logoutBtn.name }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import LifyDivider from "./Lify/LifyDivider.vue";
export default {
  components: { LifyDivider },
  name: "Slider",
  computed: {
    user() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
  },
  data() {
    return {
      logoutBtn: {
        name: this.$store.state.lang === "zh" ? "退出登录" : "Logout",
        img: require("../static/slider/logout.png"),
      },
      list: [
        {
          name: this.$store.state.lang === "zh" ? "首页" : "Home",
          img: require("../static/slider/home.png"),
          url: 0,
          isTabbar: true,
        },
        {
          name:
            this.$store.state.lang === "zh" ? "LIFY+ 充电柜" : "LIFY+ Station",
          img: require("../static/slider/station.png"),
          url: 1,
          isTabbar: true,
        },
        {
          name: this.$store.state.lang === "zh" ? "我的订单" : "Activity",
          img: require("../static/slider/activity.png"),
          url: 3,
          isTabbar: true,
        },
        {
          name: this.$store.state.lang === "zh" ? "钱包" : "Wallet",
          img: require("../static/slider/wallet.png"),
          url: "/pages/UserInfo/Wallet",
          isTabbar: false,
        },
        {
          name: this.$store.state.lang === "zh" ? "我的卡券" : "Coupons",
          img: require("../static/slider/coupons.png"),
          url: "/pages/UserInfo/Coupons",
          isTabbar: false,
        },
        {
          name: this.$store.state.lang === "zh" ? "Lify 积分" : "Lify Points",
          img: require("../static/slider/star.png"),
          url: "/pages/UserInfo/Points",
          isTabbar: false,
        },
        // {
        //   name: "邀请码",
        //   img: require("../static/slider/invite.png"),
        //   url: "/pages/UserInfo/InviteFriend",
        //   isTabbar: false,
        // },
        {
          name:
            this.$store.state.lang === "zh" ? "客服中心" : "Customer Support",
          img: require("../static/slider/support.png"),
          url: "/pages/UserInfo/Support",
          isTabbar: false,
        },
        {
          name: this.$store.state.lang === "zh" ? "设置" : "Settings",
          img: require("../static/slider/settings.png"),
          url: 4,
          isTabbar: true,
        },
      ],
    };
  },
  methods: {
    logout() {
      // console.log("logout", uni);
      uni.navigateTo({
        url: `/pages/Blank/Blank?logout=1`,
      });
    },
    navInfo(item) {
      if (item.isTabbar) {
        this.$emit("changeIndex", item.url);
        this.$emit("close");
      } else {
        uni.navigateTo({
          url: item.url,
        });
      }
    },
    navAccount() {
      uni.navigateTo({
        url: "/pages/UserInfo/AccountSetting",
      });
    },
  },
};
</script>

<style scoped>
.slider-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 73.83vw;
  height: 100vh;
  background: rgb(17, 19, 16);
  border-radius: 4.67vw 0px 0px 4.67vw;
  z-index: 1002;
  color: #fff;
}
.slider-content {
  padding: 17.76vw 8.41vw 7.01vw;
}
.user-info-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16.59vw;
}
.user-avatar {
  width: 15.89vw;
  height: 15.89vw;
  background-color: #fff;
  border-radius: 15.89vw;
  margin-right: 3.74vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-avatar img {
  width: 14.02vw;
  height: 14.02vw;
  border-radius: 14.02vw;
}
.user-info .user-name {
  height: 7.71vw;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 5.61vw;
  font-weight: 600;
  line-height: 7.71vw;
}
.user-info .user-desc {
  height: 4.44vw;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 3.27vw;
  font-weight: 300;
  line-height: 4.44vw;
}

.list-panel {
  margin: 0 5.14vw;
}
.list-item {
  display: flex;
  align-items: center;
  margin-bottom: 5.84vw;
}
.list-item .icon {
  width: 4.67vw;
  height: 4.67vw;
  margin-right: 2.1vw;
}

.list-item .icon img {
  width: 100%;
  height: 100%;
}

.footer-panel {
  position: absolute;
  bottom: 6.77vw;
}
</style>
