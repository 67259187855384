// store.js
import Vue from 'vue';
import Vuex from 'vuex';
import { myFetch } from "../api/request";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    list: [], // 在这里定义您的初始列表数据
    token: "", // token
    lang: "en", // en zh
    iframe: "",
    user: {
      "id": 0,
      "status": "",
      "avatar": "",
      "nickname": "",
      "sex": "",
      "birthday": "",
      "username": "",
      "phone": "",
      "payWithPassword": false,
      "point": 0,
      "balance": 0,
      "createAt": "",
      "registerChannel": "",
      "shareQuantity": 0,
      "invitationCode": "",
      "inviteQuantity": 0,
      "firstLogin": false,
      "pushId": "",
    }, // 用户
  },
  getters: {
    token(state) {
      return state.token
    },
    list(state) {
      return state.list
    },
    user(state) {
      return state.user
    },
    lang(state) {
      return state.lang
    },
    iframe(state) {
      return state.iframe
    }
  },
  mutations: {
    updateList(state, newList) {
      state.list = newList;
    },
    setToken(state, newToken) {
      state.token = newToken
    },
    updateUser(state, newUser) {
      state.user = newUser
    },
    updateLang(state, newLang) {
      state.lang = newLang
    },
    updateIframe(state, newIframe) {
      state.iframe = newIframe
    }
  },
  actions: {
    async fetchList({ commit, state }) {
      try {
        const response = await myFetch({
          url: "chargerBox/findAll",
          method: "POST",
          data: {},
        });
        if (response.code === 200) {
          commit('updateList', response.data);
        }
      } catch (error) {
        console.error('Error fetching list:', error);
      }
    },
    // 获取用户信息
    async fetchUserInfo({ commit, state }) {
      try {
        const response = await myFetch({
          url: "user/get",
          method: "POST",
          data: {},
        });
        if (response.code === 200) {
          commit('updateUser', response.data);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    }
  }
});

export default store;
