<template>
  <div class="activity-panel">
    <bg-option></bg-option>
    <div class="body-panel">
      <div class="title">
        {{ $store.state.lang === "zh" ? "您的订单" : "Your Activity" }}
      </div>
      <div class="list-panel">
        <div v-if="loading" class="loading-panel">
          <lify-loading />
        </div>
        <div v-else>
          <div class="list-item" v-for="item in list" :key="item.orderId">
            <lify-card width="83.41vw">
              <div class="title-icon">
                <div class="text-mini">{{ item.orderId }}</div>
                <div v-if="item.paymentStatus === 'renting'" class="status">
                  <div class="circle"></div>
                  <div>
                    {{
                      $store.state.lang === "zh" ? "待归还" : "Awaiting Return"
                    }}
                  </div>
                </div>
                <div v-if="item.paymentStatus === 'paid'" class="status paid">
                  <div class="circle"></div>
                  <div>
                    {{ $store.state.lang === "zh" ? "已归还" : "Returned" }}
                  </div>
                </div>
              </div>
              <div class="divider-panel-t">
                <lify-divider colorType="green"></lify-divider>
              </div>

              <div class="bank-info">
                <img src="../static/others/power-w.png" />
                <span>
                  {{ $store.state.lang === "zh" ? "开始时间：" : "Started:" }}
                  {{ item.rentStartAt }}
                </span>
              </div>
              <div class="bank-info">
                <img src="../static/others/power-w.png" />
                <span>
                  {{ $store.state.lang === "zh" ? "结束时间：" : "Ended:" }}
                  {{ item.rentEndAt }}
                </span>
              </div>
              <div v-if="item.duration" class="bank-info">
                <img src="../static/others/time-w.png" />
                <span>
                  {{ $store.state.lang === "zh" ? "租赁时长：" : "Duration:" }}
                  {{ formattedTime(item.duration) }}
                </span>
              </div>
              <div class="bank-info">
                <img src="../static/others/location-w.png" />
                <span>
                  {{ $store.state.lang === "zh" ? "租赁地点：" : "Location:" }}
                  {{ item.rentLocation }}
                </span>
              </div>
              <div class="bank-info">
                <img src="../static/others/money-w.png" />
                <div>
                  {{ $store.state.lang === "zh" ? "费用：" : "Total:" }}
                  ${{ item.payAmount }}
                </div>
                <div
                  v-if="item.paymentStatus === 'renting'"
                  class="money-status"
                  style="
                    color: rgb(222, 89, 97);
                    border: 0.23vw solid rgb(222, 89, 97);
                  "
                >
                  {{ $store.state.lang === "zh" ? "使用中" : "On Hold" }}
                </div>
                <div
                  v-if="item.paymentStatus === 'paid'"
                  class="money-status"
                  style="
                    color: rgb(36, 197, 81);
                    border: 0.23vw solid rgb(36, 197, 81);
                  "
                >
                  {{ $store.state.lang === "zh" ? "已支付" : "Paid" }}
                </div>
              </div>
              <div class="divider-panel-f">
                <lify-divider colorType="green"></lify-divider>
              </div>
              <div class="text-mini">
                {{
                  $store.state.lang === "zh"
                    ? `移动电源使用完毕后，您可以在地图上找到任意一个 LIFY+
                充电桩去归还。
                如果移动电源无法使用，请在5分钟内归还并及时反馈，以免产生额外的费用。`
                    : `Once finished using the power bank, you can return it at any LIFY+ Station shown in the map. If the power bank is out of service, please return it within 5 minutes and report it to avoid any fees.`
                }}
              </div>
              <div class="go-activity-detail" @click="navToDetail(item)">
                <div>
                  {{ $store.state.lang === "zh" ? "查看详情" : "View Details" }}
                </div>
                <img src="../static/others/right-arrow-green.png" />
              </div>
            </lify-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { myFetch } from "../api/request";
import BgOption from "../components/BgCommon/BgOption.vue";
import LifyCard from "../components/Lify/LifyCard.vue";
import LifyDivider from "../components/Lify/LifyDivider.vue";
import LifyLoading from "../components/LifyLoading.vue";
export default {
  components: { BgOption, LifyDivider, LifyCard, LifyLoading },
  name: "Activity",
  data() {
    return {
      loading: true,
      list: [],
    };
  },
  methods: {
    formattedTime(time) {
      if (time < 60) {
        return time + " 秒";
      } else if (time >= 60 && time < 3600) {
        var minutes = Math.floor(time / 60);
        return minutes + " 分钟";
      } else {
        var hours = Math.floor(time / 3600);
        var minutes = Math.floor((time % 3600) / 60);
        return hours + " 小时" + minutes + " 分钟";
      }
    },
    async getData() {
      if (!this.list.length) {
        this.loading = true;
      }
      const response = await myFetch({
        url: "order/findWithMe",
        method: "POST",
        data: {
          pageable: {
            page: 1,
            size: 10,
            sort: {
              desc: ["id"],
            },
          },
        },
      });
      this.loading = false;
      if (response.code == 200) {
        this.list = response.data;
      }
    },
    navToDetail(item) {
      console.log(item);
      uni.navigateTo({
        url: `/pages/index/Activity?id=${item.id}`,
      });
    },
  },
  activated() {
    this.getData();
  },
};
</script>

<style scoped>
.body-panel {
  position: relative;
  padding-top: 8.53vw;
  width: 83.41vw;
  margin: auto;
}
.title {
  height: 7.71vw;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 5.61vw;
  font-weight: 600;
  line-height: 7.71vw;
  margin-bottom: 7.24vw;
}

.title-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-mini {
  opacity: 0.4;
  color: rgb(255, 253, 247);
  font-family: Nunito;
  font-size: 2.34vw;
  font-weight: 500;
  line-height: 3.5vw;
}

.title-icon .status {
  display: flex;
  align-items: center;
}

.title-icon .status .circle {
  width: 1.4vw;
  height: 1.4vw;
  background-color: rgb(222, 89, 97);
  border-radius: 1.4vw;
}

.title-icon .status div:last-child {
  height: 3.74vw;
  color: rgb(222, 89, 97);
  font-family: Nunito;
  font-size: 2.8vw;
  font-weight: 600;
  line-height: 3.74vw;
  margin-left: 0.93vw;
}

.title-icon .status.paid .circle {
  background-color: rgb(36, 197, 81);
}

.title-icon .status.paid div:last-child {
  color: rgb(36, 197, 81);
}

.divider-panel-t {
  margin: 4.09vw 0 4.32vw;
}

.divider-panel-f {
  margin: 4.56vw 0 2.22vw;
}

.bank-info {
  display: flex;
  align-items: center;
  margin-bottom: 2.1vw;
}

.bank-info span,
.bank-info div {
  margin-left: 2.34vw;
  color: rgb(255, 253, 247);
  font-family: Mukta;
  font-size: 3.27vw;
  font-weight: 400;
  line-height: 4.91vw;
}

.money-status {
  padding: 0.7vw 4.67vw;
  box-sizing: border-box;

  border-radius: 23.36vw;
  /* 自动布局 */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
  font-size: 2.34vw;
  font-weight: 400;
  line-height: 3.27vw;
}

.bank-info:last-child {
  margin-bottom: 4.67vw;
}

.go-activity-detail {
  display: flex;
  align-items: center;
  margin-top: 1.75vw;
}

.go-activity-detail > div {
  color: rgb(36, 197, 81);
  font-family: Nunito;
  font-size: 2.33vw;
  font-weight: 600;
  line-height: 3.27vw;
}

.go-activity-detail > img {
  margin-left: 1.1vw;
  width: 2.2734vw;
  height: 2.22vw;
}

.loading-panel {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
