<template>
  <button
    class="login-btn"
    :class="[needBottom ? 'form-group' : '', reverse ? 'reverse' : '']"
    type="submit"
    @click.prevent="$emit('click')"
  >
    <div>{{ text }}</div>
    <img v-if="needIcon" src="../../static/others/arrow-right.png" />
  </button>
</template>

<script>
export default {
  name: "LifyButton",
  props: {
    classNames: String,
    // outlined: {
    //   type: Boolean,
    //   default: false,
    // },
    text: String,
    needIcon: {
      type: Boolean,
      default: true,
    },
    needBottom: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.login-btn {
  position: static;
  width: 100%;
  height: 11.21vw;
  background: rgb(36, 197, 81);
  box-shadow: 0.7vw 0.7vw 11.68vw rgba(255, 255, 255, 0.25);
  border-radius: 23.36vw;
  border: 0.47vw solid rgb(36, 197, 81);
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-btn.reverse {
  background: transparent;
}
.login-btn.reverse div {
  color: rgb(36, 197, 81);
}

.login-btn div {
  display: inline-block;
  /* primary text/extra bold */
  color: rgb(0, 0, 0);
  font-family: Nunito;
  font-size: 3.74vw;
  font-weight: 800;
}
.login-btn img {
  display: inline-block;
  height: 3.34vw;
  width: 3.34vw;
  margin-left: 0.93vw;
  transform: translateY(0.45vw);
}
</style>
