<template>
  <div class="detail-panel">
    <bg-option></bg-option>

    <div class="map-panel">
      <maps ref="map" detail :store-info="storeInfo" />
    </div>
  </div>
</template>

<script>
import BgOption from "../components/BgCommon/BgOption.vue";
import Maps from "../components/Maps.vue";
export default {
  components: { BgOption, Maps },
  name: "Detail",
  data() {
    return {
      storeInfo: {
        address: "",
        chargerQuantity: 0,
        code: "",
        creatAt: "",
        freeQuantity: 0,
        id: 0,
        latitude: 0,
        longitude: 0,
        qrCode: "",
        rentChargerQuantity: 0,
        serviceTime: "",
        status: "",
        storeName: "",
      },
    };
  },
  activated() {
    if (localStorage.getItem("storeInfo")) {
      const storeInfo = JSON.parse(localStorage.getItem("storeInfo"));
      this.storeInfo = storeInfo;
      // localStorage.removeItem("storeInfo");
      // 定位，操作结束后，移除缓存
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.map?.setCenter(
            this.storeInfo.latitude,
            this.storeInfo.longitude
          );
        }, 200);
      });
    }
  },
};
</script>

<style scoped>
.map-panel {
  width: 100vw;
  height: 100vh;
}
</style>
