<template>
  <div class="show-panel" @touchmove.prevent>
    <div class="show-area">
      <div class="text-panel">
        <div class="title">Earn $2 in Lify+ Points now!</div>
        <div class="desc">
          Complete your profile to get free $2 worth of Lify+ Points
        </div>
      </div>
      <div class="btn-panel">
        <div class="cancel-panel" @click="$emit('close')">Cancel</div>
        <div id="go-accountsetting" class="confirm-panel" @click="navTo">
          Lets go!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullInfo",
  methods: {
    navTo() {
      const that = this;
      bth?.addEventListener("click", function () {
        uni.navigateTo({
          url: "/pages/UserInfo/AccountSetting",
        });
        that.$emit("close");
      });
    },
  },
};
</script>

<style scoped>
.fullInfo-enter-active {
  animation: showArea 0.3s;
}

.fullInfo-leave-active {
  animation: showArea 0.3s reverse;
}

@keyframes showArea {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.show-panel {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}

.show-area {
  box-sizing: border-box;
  position: fixed;
  bottom: calc(4.01vw + env(safe-area-inset-bottom));
  width: 86.21vw;
  height: 50.47vw;
  margin-left: 6.895vw;
  background: rgb(17, 19, 16);
  border-radius: 4.67vw;
  padding: 12.62vw 7.01vw;
}

.text-panel {
  padding-bottom: 4.67vw;
}

.text-panel .title {
  color: rgb(255, 253, 247);
  font-family: Nunito;
  font-size: 4.21vw;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 1.87vw;
}

.text-panel .desc {
  color: rgb(255, 253, 247);
  font-family: Nunito;
  font-size: 2.8vw;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
}

.btn-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cancel-panel {
  width: 35.28vw;
  height: 9.35vw;
  box-sizing: border-box;
  border: 0.23vw solid rgb(255, 255, 255);
  border-radius: 23.36vw;
  /* 自动布局 */
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 3.27vw;
  font-weight: 800;
  line-height: 4.44vw;
}

.confirm-panel {
  width: 35.28vw;
  height: 9.35vw;
  box-sizing: border-box;
  /* main colors/FFFFFF */
  background-color: rgb(36, 197, 81);
  border: 0.23vw solid rgb(36, 197, 81);
  box-shadow: 0.7vw 0.7vw 7.01vw rgba(255, 255, 255, 0.25);
  border-radius: 23.36vw;
  /* 自动布局 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* sec text/extra bold */
  color: rgb(0, 0, 0);
  font-family: Nunito;
  font-size: 3.27vw;
  font-weight: 800;
  line-height: 4.44vw;
}
</style>
