<template>
  <div id="index">
    <!-- 侧边栏 -->
    <div class="slider-icon" @click="showSliderFn">
      <img src="../static/others/slider.png" />
    </div>
    <transition name="sliderPanel">
      <slider
        v-if="showSlider"
        @close="showSlider = false"
        @changeIndex="changeIndex"
      ></slider>
    </transition>
    <transition name="sliderBg">
      <div
        v-if="showSlider"
        class="bank-detail-panel"
        @click="showSlider = false"
      ></div>
    </transition>
    <keep-alive>
      <template v-if="activeIndex === 0">
        <home ref="home" :lat="lat" :long="long" />
      </template>
      <template v-if="activeIndex === 1">
        <station />
      </template>
      <template v-if="activeIndex === 3">
        <activity />
      </template>
      <template v-if="activeIndex === 4">
        <my />
      </template>
    </keep-alive>

    <tab-bar :activeIndex="activeIndex" @changeIndex="changeIndex"></tab-bar>

    <!-- 完善资料 -->
    <transition name="fullInfoBg">
      <div v-if="showFullInfo" class="full-info-bg-panel"></div>
    </transition>
    <transition name="fullInfo">
      <full-info v-if="showFullInfo" @close="showFullInfo = false"> </full-info>
    </transition>
  </div>
</template>

<script>
import Activity from "./Activity.vue";
import Home from "./Home.vue";
import My from "./My.vue";
import Station from "./Station.vue";
import FullInfo from "../components/FullInfo.vue";
import TabBar from "../components/TabBar.vue";
import { myFetch } from "../api/request";
import Slider from "../components/Slider.vue";

export default {
  name: "Index",
  components: {
    Home,
    TabBar,
    Station,
    Activity,
    My,
    FullInfo,
    Slider,
  },
  data() {
    return {
      activeIndex: 0,
      showFullInfo: false,
      showSlider: false,
      lat: 40.74854,
      long: -73.98576,
    };
  },
  methods: {
    showSliderFn() {
      this.showSlider = true;
    },
    // 标签栏
    changeIndex(index) {
      this.activeIndex = index;
    },
    // 是否第一次登录
    async checkFirst() {
      const response = await myFetch({
        url: "user/checkFirstLogin",
        method: "POST",
      });
      if (response.code === 200) {
        if (response.data) {
          // 弹出完善资料
          this.showFullInfo = true;
        }
      }
    },
  },
  created() {
    const token = new URLSearchParams(window.location.search).get("q");
    const i = new URLSearchParams(window.location.search).get("i");
    const lat = new URLSearchParams(window.location.search).get("lat");
    const long = new URLSearchParams(window.location.search).get("long");
    const lang =
      new URLSearchParams(window.location.search).get("lang") || "en";
    const iframe =
      new URLSearchParams(window.location.search).get("iframe") || "mp";
      
    if (token) {
      this.$store.commit("setToken", token);
      // 获取是否第一次登录
      this.checkFirst();
      this.$store.dispatch("fetchUserInfo");
    }
    if (i && Number(i)) {
      const iNumber = Number(i);
      this.changeIndex(iNumber);
    }
    if (lat) {
      this.lat = lat;
    }
    if (long) {
      this.long = long;
    }
    if ((lang && lang === "en") || lang === "zh") {
      this.$store.commit("updateLang", lang);
    }
    if (iframe) {
      this.$store.commit("updateIframe", iframe);
      console.log(this.$store.state.iframe);
    }
  },
};
</script>

<style>
@import url(../index.css);

.full-info-bg-panel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
}

.fullInfoBg-enter-active {
  animation: fullInfoBg 0.3s;
}

.fullInfoBg-leave-active {
  animation: fullInfoBg 0.3s reverse;
}
@keyframes fullInfoBg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slider-icon {
  position: absolute;
  margin-left: auto;
  right: 7.01vw;
  padding-top: 3.5vw;
  width: 7.01vw;
  height: 7.01vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.slider-icon img {
  width: 3.27vw;
  height: 2.8vw;
}

.sliderBg-enter-active {
  animation: sliderBg 0.3s;
}

.sliderBg-leave-active {
  animation: sliderBg 0.3s reverse;
}
@keyframes sliderBg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sliderPanel-enter-active {
  animation: slider 0.3s;
}
.sliderPanel-leave-active {
  animation: slider 0.3s reverse;
}
@keyframes slider {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.bank-detail-panel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1001;
}
</style>
