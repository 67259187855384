<template>
  <div class="station-panel">
    <bg-option></bg-option>
    <div class="body-panel">
      <div class="title">
        {{ $store.state.lang === "zh" ? "LIFY+ 充电柜" : "LIFY+ Station" }}
      </div>
      <div class="list-panel">
        <DynamicScroller class="scroller" :items="powers" :min-item-size="300">
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem
              :item="item"
              :active="active"
              :size-dependencies="[index]"
              :data-index="index"
            >
              <lify-card width="83.41vw">
                <div class="title-icon">
                  <img src="../static/others/location.png" />
                  <span>
                    {{ $store.state.lang === "zh" ? "地址" : "Location" }}
                  </span>
                </div>
                <div class="title-content">
                  {{ item.address }}
                </div>
                <div class="divider-panel">
                  <lify-divider colorType="green"></lify-divider>
                </div>
                <div class="bank-info">
                  <img src="../static/others/power.png" />
                  <span>
                    {{ item.freeQuantity }}
                    {{
                      $store.state.lang === "zh"
                        ? "台可用的设备"
                        : "Devices Available"
                    }}
                  </span>
                </div>
                <div v-if="item.distance" class="bank-info">
                  <img src="../static/others/walk.png" />
                  <span>
                    {{
                      $store.state.lang === "zh"
                        ? `离您当前位置${item.distance}米`
                        : `${item.distance}m away from your location`
                    }}
                  </span>
                </div>
                <div class="bank-info">
                  <img src="../static/others/star.png" />
                  <span>
                    {{
                      $store.state.lang === "zh"
                        ? `设备编码: ${item.code}`
                        : `Device Number: ${item.code}`
                    }}
                  </span>
                </div>
                <lify-button
                  :text="$store.state.lang === 'zh' ? '导航至充电桩' : 'Navigate'"
                  @click="navToDetail(item)"
                ></lify-button>
              </lify-card>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </div>
    </div>
  </div>
</template>

<script>
import { DynamicScrollerItem } from "vue-virtual-scroller";
import BgOption from "../components/BgCommon/BgOption.vue";
import LifyButton from "../components/Lify/LifyButton.vue";
import LifyCard from "../components/Lify/LifyCard.vue";
import LifyDivider from "../components/Lify/LifyDivider.vue";
export default {
  components: {
    LifyCard,
    LifyButton,
    BgOption,
    LifyDivider,
    DynamicScrollerItem,
  },
  name: "Station",
  computed: {
    powers() {
      return this.$store.getters.list;
    },
  },
  methods: {
    navToDetail(item) {
      localStorage.setItem("storeInfo", JSON.stringify(item));
      this.$router.push(`/detail`);
    },
  },
};
</script>

<style scoped>
.scroller {
  height: calc(100vh - 24vw);
}
.body-panel {
  position: relative;
  padding-top: 8.53vw;
  width: 83.41vw;
  margin: auto;
}
.title {
  height: 7.71vw;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 5.61vw;
  font-weight: 600;
  line-height: 7.71vw;
  margin-bottom: 7.24vw;
}

.title-icon {
  display: flex;
  align-items: center;
}

.title-icon span {
  opacity: 0.4;
  color: rgb(255, 253, 247);
  font-family: Nunito;
  font-size: 2.34vw;
  font-weight: 400;
  line-height: 3.27vw;
  margin-left: 1.17vw;
}

.title-content {
  margin-top: 1.87vw;
  color: rgb(255, 253, 247);
  font-family: Nunito;
  font-size: 3.74vw;
  font-weight: 500;
  line-height: 5.61vw;
}

.divider-panel {
  margin: 4.67vw 0;
}

.bank-info {
  display: flex;
  align-items: center;
  margin-bottom: 2.1vw;
}

.bank-info span {
  margin-left: 2.34vw;
  height: 4.91vw;
  color: rgb(255, 253, 247);
  font-family: Mukta;
  font-size: 3.27vw;
  font-weight: 400;
  line-height: 4.91vw;
}

.bank-info:last-child {
  margin-bottom: 4.67vw;
}

img {
  width: 3.27vw;
  height: 3.27vw;
}
</style>
